
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.search-faq-items {
  background: $color-white;
  border-radius: $base-border-radius * 2;
  padding: 16px;
}

.search-faq-item {
  display: block;
  padding: 16px;
  border-radius: 12px;
  transition: background $base-animation-time $base-animation-function;

  &__title {
    margin-bottom: 8px;
  }

  &__caption {
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
  }

  &:hover {
    background: $color-black-op-05;
  }
}
